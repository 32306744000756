import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography, ButtonBase, Icon, Popover, Grid, Tooltip } from '@material-ui/core';
import { reset } from 'redux-form';
import NotesModal from '../Modals/NotesModal';
import { getSliderTextValue, getWindShieldTextValue } from '../../utils';
import { modalsToggle } from '../../actions';
import infoIcon from '../../img/info.svg';
import relistIcon from '../../img/relist.svg';
import { roles } from '../../constants';
import CustomTooltip from '../Tooltip';
import { useState, useRef } from 'react';
import { useIsTextOverflown } from '../../hooks';
import FormattedAmount from 'components/FormattedAmount';
import { RelistDialog } from '../RelistDialog/RelistDialog';
import { RelistHistory } from '../RelistHistory';
import { PullVehicleModal } from '../../pages/Admin/AuctionDetail/components/AdminPullModal';

const styles = () => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#616161',
    fontSize: 14,
    opacity: 1
  },
  popper: {
    backgroundColor: '#ffffff',
    opacity: 1,
    wordBreak: 'break-word',
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
  }
});

const StyledVehicleTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 180px;
`;

const StyledImg = styled.img`
  height: 24px;
`;

const StyledVehicleDescription = styled(Typography)`
  color: #757575;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

const StyledVehicleTitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

const StyledBackButton = styled(ButtonBase)`
  margin-left: 10px;
  span {
    font-size: 24px;
  }
`;

const StyledInfoButton = styled(ButtonBase)`
  span {
    font-size: 18px;
  }
`;

const StyledGridContainer = styled(Grid)`
  max-width: 400px;
  padding: 6px;
`;

const StyledGridItemLabel = styled(Grid)`
  color: #616161;
  padding: 4px 8px;
`;

const StyledGridItem = styled(Grid)`
  color: #757575;
  padding: 4px 8px;
`;

const StyledOrderIndexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
`;

const createOtherOfferInitialValues = (type, offers) => {
  if (typeof offers === 'number') {
    return {
      [`${type}_opening_bid`]: offers,
      [`${type}_bid`]: null,
      [`${type}_sold`]: null
    };
  }

  return {
    [`${type}_opening_bid`]: offers?.opening_bid,
    [`${type}_bid`]: offers?.bid,
    [`${type}_sold`]: offers?.sold,
    [`${type}_fake_user_id`]: offers?.fake_user_id,
    [`${type}_checkbox`]: offers?.checked
  };
};

const VehiclePopoverFC = ({
  vehicleData,
  classes,
  toggleNotesModal,
  resetForm,
  open: isOpened,
  modalId,
  currentUser,
  showNotes,
  showRelistHistory,
  showLister = false
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRelistModalOpen, setIsRelistModalOpen] = useState(false);
  const titleRef = useRef();
  const isTextOverflown = useIsTextOverflown(titleRef);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const vehicleTitle = `${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`;
  const open = Boolean(anchorEl);
  const isAdmin = currentUser.user.role === roles.ADMIN;

  const renderRelistButton = showRelistHistory && vehicleData.is_newly_relisted;

  return (
    <StyledWrapper>
      <StyledOrderIndexContainer>
        {vehicleData.orderIndex && <StyledVehicleTitle>{vehicleData.orderIndex}</StyledVehicleTitle>}
        {renderRelistButton && (
          <StyledInfoButton onClick={() => setIsRelistModalOpen(true)}>
            <StyledImg src={relistIcon} alt="relist-icon" />
          </StyledInfoButton>
        )}
        {renderRelistButton && (
          <RelistHistory
            vehicleId={vehicleData.id}
            isOpen={isRelistModalOpen}
            close={() => setIsRelistModalOpen(false)}
          />
        )}
      </StyledOrderIndexContainer>

      <StyledVehicleTitleContainer>
        <CustomTooltip title={vehicleTitle} arrow light isTextOverflown={isTextOverflown}>
          <StyledVehicleTitle ref={titleRef}>{vehicleTitle}</StyledVehicleTitle>
        </CustomTooltip>
        {isAdmin && showNotes ? (
          <Tooltip
            disableFocusListener
            disableTouchListener
            classes={{ tooltip: classes.tooltip, popper: classes.popper }}
            title={
              vehicleData.admin_notes || vehicleData.reserve_price || vehicleData.starting_bid ? (
                <StyledGridContainer container>
                  {vehicleData.admin_notes ? (
                    <StyledGridItemLabel item xs={12}>
                      {vehicleData.admin_notes}
                    </StyledGridItemLabel>
                  ) : null}
                  {vehicleData.reserve_price ? (
                    <Fragment>
                      <StyledGridItem item xs={12}>
                        <FormattedAmount amount={vehicleData.reserve_price} />
                      </StyledGridItem>
                    </Fragment>
                  ) : null}
                  {vehicleData.starting_bid ? (
                    <Fragment>
                      <StyledGridItem item xs={12}>
                        <FormattedAmount amount={vehicleData.starting_bid} />
                      </StyledGridItem>
                    </Fragment>
                  ) : null}
                </StyledGridContainer>
              ) : (
                'Click to add notes'
              )
            }
          >
            <StyledInfoButton onClick={() => toggleNotesModal(`notesModal_${vehicleData.id}`)}>
              <StyledImg src={infoIcon} alt="info" />
            </StyledInfoButton>
          </Tooltip>
        ) : null}
      </StyledVehicleTitleContainer>
      <StyledVehicleDescription>
        {`${vehicleData.vin}`}
        <StyledBackButton onClick={handleClick}>
          <Icon>expand_more</Icon>
        </StyledBackButton>
      </StyledVehicleDescription>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <StyledGridContainer container>
          {showLister && (
            <>
              <StyledGridItemLabel item xs={6}>
                Lister:
              </StyledGridItemLabel>
              <StyledGridItem item xs={6}>
                {vehicleData.lister?.options?.name_for_bio ?? 'N/A'}
              </StyledGridItem>
            </>
          )}
          <StyledGridItemLabel item xs={6}>
            Extension times:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.times_extended}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Mileage:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.mileage}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Engine:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.engine}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Transmission:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.transmission}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Check Engine Light:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.check_engine_light ? 'on' : 'off'}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            ABS Light:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.abs_light ? 'on' : 'off'}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Airbag Light:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.airbag_light ? 'on' : 'off'}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Windshield:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getWindShieldTextValue(vehicleData.windshield)}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Tires:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.tiers)}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Paint:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.paint)}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Interior:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.interior)}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Air Conditioning Blows:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.air_conditioning_blows, ['Cold', 'Cool', 'Warm', '', 'N/A'])}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Engine Oil:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.engine_oil, ['Very Clean', 'Clean', 'Dirty', 'Milky', 'N/A'])}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Engine Coolant:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.engine_coolant, ['Very Clean', 'Clean', 'Dirty', 'Milky', 'N/A'])}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Dealer Comments:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.dealer_comments}
          </StyledGridItem>
        </StyledGridContainer>
      </Popover>
      {isOpened && modalId === `notesModal_${vehicleData.id}` ? (
        <NotesModal
          modalId={`notesModal_${vehicleData.id}`}
          vehicle={vehicleData}
          initialValues={{
            adminNotes: vehicleData.admin_notes || '',
            reserve_price: vehicleData.reserve_price || '',
            starting_bid: vehicleData.starting_bid ?? 100,
            ...createOtherOfferInitialValues('acv', vehicleData.other_offers?.acv),
            ...createOtherOfferInitialValues('other', vehicleData.other_offers?.other),
            ...createOtherOfferInitialValues('open_lane', vehicleData.other_offers?.open_lane)
          }}
          resetForm={id => resetForm(id)}
        />
      ) : null}
    </StyledWrapper>
  );
};

const mapStateToProps = state => ({
  open: state.modals.open,
  modalId: state.modals.modalId,
  currentUser: state.user
});

const mapDispatchToProps = dispatch => ({
  toggleNotesModal: modalId => dispatch(modalsToggle(modalId)),
  resetForm: modalId => {
    dispatch(reset(modalId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VehiclePopoverFC));
