import React, { Component, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { Button, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import ModalWrapper from '../../../../../../components/ModalWrapper';
import { editBid, editProxyBid, deleteBid, deleteProxyBid } from '../../../../../../actions/bids';
import { convertToLocalTime, normalizeFormattedField } from '../../../../../../utils';
import { FormFormattedField } from '../../../../../../components/Form';
import { selectedVehicleProxyBidSelector, userRoleSelector } from '../../../../../../selectors';
import { COLOR_SUCCESSFUL, BID_TYPES, roles } from '../../../../../../constants';
import { required } from '../../../../../../utils/validators';
import adminOffersApi, {
  useRemoveAllBidsMutation,
  useRemoveAllOffersMutation,
  useRemoveOfferBidMutation,
  useRemoveProxyOfferBidMutation,
  useUpdateOfferBidMutation,
  useUpdateProxyOfferBidMutation
} from 'services/api/admin-offers';
import { change } from 'redux-form';
import api from 'utils/api';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { modalsHide, modalsToggle, snackShow } from 'actions';
import { useParams } from 'react-router-dom';

const StyledModalWrapper = styled(ModalWrapper)`
  > div:nth-child(2) {
    max-width: 715px;
    width: 100%;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 650px;
  gap: 24px;
  max-height: 400px;
  overflow: auto;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
`;

const Title = styled.div`
  font-size: 1.3125rem;
  color: #000;
  font-weight: 500;
`;

const StyledTruncatedTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledGrid = styled(Grid)`
  padding: 5px 0;
`;

const StyledForm = styled.form`
  @media only screen and (max-width: 820px) {
    width: 715px;
    display: block;
  }
`;

const StyledFormFormattedField = styled(FormFormattedField)`
  max-width: 75px;
  input {
    color: ${COLOR_SUCCESSFUL};
  }
`;

const configByType = {
  bids: {
    title: 'All Bids',
    modalId: 'view-all-bids',
    editButton: 'Edit Bids',
    deleteButton: 'Delete all bids',
    log: 'Bid Log',
    confirmDeleteAll: 'Are you sure you want to remove all bids?'
  },
  offers: {
    title: 'All Offers',
    modalId: 'view-all-offers',
    editButton: 'Edit Offers',
    deleteButton: 'Delete all offers',
    log: 'Highest Offer',
    confirmDeleteAll: 'Are you sure you want to remove all offers?'
  }
};

const RenderForm = ({
  bids,
  handleSubmit,
  removeProxyBid,
  proxyBid,
  removeBid,
  initForm,
  type,
  formName,
  submitChangeWithType
}) => {
  const [lastBid, ...restBids] = bids;
  return (
    <StyledForm
      name={formName}
      onSubmit={handleSubmit}
      style={{ overflowY: 'scroll', maxHeight: 300, flexWrap: 'nowrap' }}
    >
      {proxyBid && (
        <>
          <Typography>Proxy:</Typography>
          <StyledGrid spacing={16} container alignItems="center">
            <Grid item xs={2}>
              <Field
                name="proxy_bid_amount"
                placeholder="Enter Proxy Bid Amount"
                component={StyledFormFormattedField}
                thousandSeparator
                allowNegative={false}
                fixedDecimalScale
                prefix="$"
                disableUnderline
                validate={required}
                normalize={normalizeFormattedField}
                hideErrors
              />
            </Grid>
            <Grid item xs={3} title={proxyBid.bidder?.dealership_name}>
              <StyledTruncatedTypography>{`by ${proxyBid.bidder?.dealership_name}`}</StyledTruncatedTypography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{convertToLocalTime(proxyBid.created_at, true)}</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              container
              justify="space-between"
              style={{ gap: 4, flexDirection: 'row', flexWrap: 'nowrap' }}
            >
              <Button
                style={{ flex: '1 1 50%' }}
                variant="contained"
                color="primary"
                onClick={() => submitChangeWithType(BID_TYPES.PROXY)}
              >
                Save
              </Button>
              {type === 'bids' && (
                <Button
                  style={{ flex: '1 1 50%' }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    const isDeletingConfirmed = window.confirm(
                      `Are you sure that you want to delete ${proxyBid.bidder?.dealership_name}'s bid`
                    );
                    if (isDeletingConfirmed) {
                      removeProxyBid(proxyBid.id);
                    }
                  }}
                >
                  Delete
                </Button>
              )}
            </Grid>
          </StyledGrid>
          <Typography>{configByType[type]?.log}:</Typography>
        </>
      )}
      {lastBid && (
        <StyledGrid spacing={16} container alignItems="center">
          <Grid item xs={2}>
            <Field
              name="bid_amount"
              placeholder="Enter Bid Amount"
              component={StyledFormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              disableUnderline
              validate={required}
              normalize={normalizeFormattedField}
              hideErrors
            />
          </Grid>
          <Grid item xs={3} title={lastBid.bidder}>
            <StyledTruncatedTypography>{`by ${lastBid.bidder}`}</StyledTruncatedTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{lastBid.date}</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            container
            justify="space-between"
            style={{ gap: 4, flexDirection: 'row', flexWrap: 'nowrap' }}
          >
            <Button
              style={{ flex: '1 1 50%' }}
              variant="contained"
              color="primary"
              onClick={() => submitChangeWithType(BID_TYPES.NORMAL)}
            >
              Save
            </Button>
            {type === 'bids' && (
              <Button
                style={{ flex: '1 1 50%' }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  const isDeletingConfirmed = window.confirm(
                    `Are you sure that you want to delete ${lastBid.bidder}'s bid`
                  );
                  if (isDeletingConfirmed) {
                    removeBid(lastBid.id);
                    initForm();
                  }
                }}
              >
                Delete
              </Button>
            )}
          </Grid>
        </StyledGrid>
      )}
      {restBids.map((bid, index) => (
        <StyledGrid container spacing={16} alignItems="baseline" key={index}>
          <Grid item xs={2}>
            <NumberFormat displayType="text" prefix="$" value={bid.bid} thousandSeparator />
          </Grid>
          <Grid item xs={3} title={bid.bidder}>
            <StyledTruncatedTypography>{`by ${bid.bidder}`}</StyledTruncatedTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{bid.date}</Typography>
          </Grid>
          <Grid item xs={4} container justify="space-between" title="You can edit or delete only the last bid">
            <Button variant="contained" disabled style={{ width: 'calc(50% - 4px)' }}>
              Save
            </Button>
            {/* {type === 'bids' && (
                <Button variant="contained" disabled>
                  Delete
                </Button>
              )} */}
          </Grid>
        </StyledGrid>
      ))}
    </StyledForm>
  );
};

const RenderList = ({ bids }) => {
  const user = useSelector(state => state.user.user);
  return (
    <Grid container direction="column" style={{ overflowY: 'scroll', maxHeight: 300, flexWrap: 'nowrap' }}>
      {bids.map((bid, index) => (
        <Grid container item xs={12} key={index} alignItems="center">
          <Grid item xs={6} sm={2} style={{ flexBasis: '10%', maxWidth: '10%' }}>
            <NumberFormat displayType="text" prefix="$" value={bid.bid} thousandSeparator />
          </Grid>
          <Grid item xs={6} sm={4} style={{ flexBasis: '65%', maxWidth: '65%' }}>
            <Typography style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{`by ${
              bid.bidder
            } ${bid.admin && user?.role === 'admin' ? `(AA ${bid.admin.first_name})` : ''}`}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} style={{ flexBasis: '25%', maxWidth: '25%' }}>
            <Typography>{bid.date}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export const ViewAllBidsModalFC = ({ type, submit, bids, proxyBid, handleSubmit, vehicleId }) => {
  const dispatch = useDispatch();

  const [removeOfferBid] = useRemoveOfferBidMutation();
  const [removeProxyOfferBid] = useRemoveProxyOfferBidMutation();
  const [updateOfferBid] = useUpdateOfferBidMutation();
  const [updateProxyOfferBid] = useUpdateProxyOfferBidMutation();
  const [removeAllOffers] = useRemoveAllOffersMutation();
  const [removeAllBidsMutation] = useRemoveAllBidsMutation();

  const formName = `adminEditBidModalForm-${type}`;

  const user = useSelector(state => state.user.user);

  const actions =
    type === 'bids'
      ? {
          removeBid: payload => dispatch(deleteBid(payload).request),
          removeProxyBid: payload => dispatch(deleteProxyBid(payload).request),
          updateBid: payload => dispatch(editBid(payload).request),
          updateProxyBid: payload => dispatch(editProxyBid(payload).request),
          removeAllBids: removeAllBidsMutation
        }
      : {
          removeBid: removeOfferBid,
          removeProxyBid: removeProxyOfferBid,
          updateBid: updateOfferBid,
          updateProxyBid: updateProxyOfferBid,
          removeAllBids: removeAllOffers
        };

  const [isFormToggled, setIsFormToggled] = useState(false);

  useEffect(() => {
    if (type !== 'bids') return;
    const [lastBid] = bids;
    if (lastBid) dispatch(change(formName, 'bid_amount', lastBid.bid));
  }, [bids?.length, dispatch, type, formName]);

  const { removeBid, removeProxyBid, removeAllBids } = actions ?? {};

  const submitChangeWithType = type => {
    dispatch(change(formName, 'type', type));
    // Wait to update redux store, and then submit change
    console.log(submit);
    setTimeout(submit, 0);
  };

  const initForm = () => {
    console.log('init form trigger', bids[0], proxyBid);
    const [lastBid] = bids;
    if (lastBid) dispatch(change(formName, 'bid_amount', lastBid.bid));
    if (proxyBid) dispatch(change(formName, 'proxy_bid_amount', proxyBid.amount));
  };

  const toggleForm = () => setIsFormToggled(curr => !curr);

  const hideForm = () => {
    if (isFormToggled) {
      setIsFormToggled(false);
    }
  };

  const renderForm = () => {
    const [lastBid, ...restBids] = bids;
    return (
      <StyledForm onSubmit={handleSubmit}>
        {proxyBid && (
          <>
            <Typography>Proxy:</Typography>
            <StyledGrid spacing={16} container alignItems="center">
              <Grid item xs={2}>
                <Field
                  name="proxy_bid_amount"
                  placeholder="Enter Proxy Bid Amount"
                  component={StyledFormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  disableUnderline
                  validate={required}
                  normalize={normalizeFormattedField}
                  hideErrors
                />
              </Grid>
              <Grid item xs={4} title={proxyBid.bidder?.dealership_name}>
                <StyledTruncatedTypography>{`by ${proxyBid.bidder?.dealership_name}`}</StyledTruncatedTypography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{convertToLocalTime(proxyBid.created_at, true)}</Typography>
              </Grid>
              <Grid item xs={3} container justify="space-between">
                <Button variant="contained" color="primary" onClick={() => submitChangeWithType(BID_TYPES.PROXY)}>
                  Save
                </Button>
                {type === 'bids' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      const isDeletingConfirmed = window.confirm(
                        `Are you sure that you want to delete ${proxyBid.bidder?.dealership_name}'s bid`
                      );
                      if (isDeletingConfirmed) {
                        removeProxyBid(proxyBid.id);
                      }
                    }}
                  >
                    Delete
                  </Button>
                )}
              </Grid>
            </StyledGrid>
            <Typography>{configByType[type]?.log}:</Typography>
          </>
        )}
        {lastBid && (
          <StyledGrid spacing={16} container alignItems="center">
            <Grid item xs={2}>
              <Field
                name="bid_amount"
                placeholder="Enter Bid Amount"
                component={StyledFormFormattedField}
                thousandSeparator
                allowNegative={false}
                fixedDecimalScale
                prefix="$"
                disableUnderline
                validate={required}
                normalize={normalizeFormattedField}
                hideErrors
              />
            </Grid>
            <Grid item xs={4} title={lastBid.bidder}>
              <StyledTruncatedTypography>{`by ${lastBid.bidder}`}</StyledTruncatedTypography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{lastBid.date}</Typography>
            </Grid>
            <Grid item xs={3} container justify="space-between">
              <Button variant="contained" color="primary" onClick={() => submitChangeWithType(BID_TYPES.NORMAL)}>
                Save
              </Button>
              {type === 'bids' && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    const isDeletingConfirmed = window.confirm(
                      `Are you sure that you want to delete ${lastBid.bidder}'s bid`
                    );
                    if (isDeletingConfirmed) {
                      removeBid(lastBid.id);
                      initForm();
                    }
                  }}
                >
                  Delete
                </Button>
              )}
            </Grid>
          </StyledGrid>
        )}
        {restBids.map((bid, index) => (
          <StyledGrid container spacing={16} alignItems="baseline" key={index}>
            <Grid item xs={2}>
              <NumberFormat displayType="text" prefix="$" value={bid.bid} thousandSeparator />
            </Grid>
            <Grid item xs={4} title={bid.bidder}>
              <StyledTruncatedTypography>{`by ${bid.bidder}`}</StyledTruncatedTypography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{bid.date}</Typography>
            </Grid>
            <Grid item xs={3} container justify="space-between" title="You can edit or delete only the last bid">
              <Button variant="contained" disabled>
                Save
              </Button>
              {/* {type === 'bids' && (
                <Button variant="contained" disabled>
                  Delete
                </Button>
              )} */}
            </Grid>
          </StyledGrid>
        ))}
      </StyledForm>
    );
  };

  const renderList = () => {
    return (
      <Grid container direction="column">
        {bids.map((bid, index) => (
          <Grid container item xs={12} key={index} alignItems="center">
            <Grid item xs={6} sm={2}>
              <NumberFormat displayType="text" prefix="$" value={bid.bid} thousandSeparator />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography>{`by ${bid.bidder} ${
                bid.admin && user?.role === 'admin' ? `(AA ${bid.admin.first_name})` : ''
              }`}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>{bid.date}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  const userRole = useSelector(state => state?.user?.user?.role);
  const userJob = useSelector(state => state?.user?.user?.job_title);

  const { title, modalId, editButton, deleteButton, confirmDeleteAll } = configByType[type];

  const cancelText = (() => {
    if (userRole !== roles.ADMIN) {
      return 'Ok';
    }
    return isFormToggled ? 'Done' : editButton;
  })();

  const handleRemoveAll = useCallback(async () => {
    try {
      const { error } = await removeAllBids({ vehicleId });
      if (!error) {
        dispatch(snackShow({ type: 'error', message: `Removed all ${type}` }));
        window.location.reload();
      } else {
        dispatch(snackShow({ type: 'error', message: `Failed to remove all ${type}` }));
      }
    } catch (error) {
      dispatch(snackShow({ type: 'error', message: `Failed to remove all ${type}` }));
    }
    dispatch(modalsToggle(`remove-${modalId}`));
  }, []);

  const openRemoveModal = () => {
    dispatch(modalsHide(modalId));
    dispatch(modalsToggle(`remove-${modalId}`));
  };

  return (
    <>
      <StyledModalWrapper
        title={title}
        modalId={modalId}
        cancelText={cancelText}
        showAdditionalButton={userRole === roles.ADMIN && ['Admin', 'Manager'].includes(userJob) && !isFormToggled}
        additionalButtonText={deleteButton}
        additionalButtonAction={openRemoveModal}
        showSubmit={false}
        onEnter={initForm}
        cancelCustomAction={userRole === roles.ADMIN ? toggleForm : null}
        onClose={hideForm}
      >
        {roles.ADMIN === userRole && isFormToggled ? renderForm() : renderList()}
      </StyledModalWrapper>
      <ConfirmModal
        title={confirmDeleteAll}
        handleSubmit={handleRemoveAll}
        submitText={'Delete'}
        modalId={`remove-${modalId}`}
      />
    </>
  );
};

const ViewAllBidsTooltipFC = ({ type, submit, bids, proxyBid, handleSubmit, vehicleId }) => {
  const dispatch = useDispatch();

  const [removeOfferBid] = useRemoveOfferBidMutation();
  const [removeProxyOfferBid] = useRemoveProxyOfferBidMutation();
  const [updateOfferBid] = useUpdateOfferBidMutation();
  const [updateProxyOfferBid] = useUpdateProxyOfferBidMutation();
  const [removeAllOffers] = useRemoveAllOffersMutation();
  const [removeAllBidsMutation] = useRemoveAllBidsMutation();

  const formName = `adminEditBidModalForm-${type}`;

  const actions =
    type === 'bids'
      ? {
          removeBid: payload => dispatch(deleteBid(payload).request),
          removeProxyBid: payload => dispatch(deleteProxyBid(payload).request),
          updateBid: payload => dispatch(editBid(payload).request),
          updateProxyBid: payload => dispatch(editProxyBid(payload).request),
          removeAllBids: removeAllBidsMutation
        }
      : {
          removeBid: removeOfferBid,
          removeProxyBid: removeProxyOfferBid,
          updateBid: updateOfferBid,
          updateProxyBid: updateProxyOfferBid,
          removeAllBids: removeAllOffers
        };

  const [isFormToggled, setIsFormToggled] = useState(false);

  useEffect(() => {
    if (type !== 'bids') return;
    const [lastBid] = bids;
    if (lastBid) dispatch(change(formName, 'bid_amount', lastBid.bid));
  }, [bids?.length, dispatch, type, formName]);

  const { removeBid, removeProxyBid, removeAllBids } = actions ?? {};

  const submitChangeWithType = type => {
    dispatch(change(formName, 'type', type));
    setTimeout(submit, 0);
  };

  const initForm = () => {
    const [lastBid] = bids;
    if (lastBid) dispatch(change(formName, 'bid_amount', lastBid.bid));
    if (proxyBid) dispatch(change(formName, 'proxy_bid_amount', proxyBid.amount));
  };

  const toggleForm = () => setIsFormToggled(curr => !curr);

  const toggleWithInit = () => {
    toggleForm();
    initForm();
  };

  const hideForm = () => {
    if (isFormToggled) {
      setIsFormToggled(false);
    }
  };

  const userRole = useSelector(state => state?.user?.user?.role);
  const userJob = useSelector(state => state?.user?.user?.job_title);

  const { title, modalId, editButton, deleteButton, confirmDeleteAll } = configByType[type];

  const cancelText = (() => {
    if (userRole !== roles.ADMIN) {
      return 'Ok';
    }
    return isFormToggled ? 'Done' : editButton;
  })();

  const handleRemoveAll = useCallback(async () => {
    try {
      const { error } = await removeAllBids({ vehicleId });
      if (!error) {
        dispatch(snackShow({ type: 'error', message: 'Removed all bids' }));
        window.location.reload();
      } else {
        dispatch(snackShow({ type: 'error', message: 'Failed to remove all bids' }));
      }
    } catch (error) {
      dispatch(snackShow({ type: 'error', message: 'Failed to remove all bids' }));
    }
    dispatch(modalsToggle(`remove-${modalId}`));
  }, []);

  const openRemoveModal = () => {
    dispatch(modalsHide(modalId));
    dispatch(modalsToggle(`remove-${modalId}`));
  };

  return (
    <TooltipWrapper>
      <Title>{title}</Title>
      {roles.ADMIN === userRole && isFormToggled ? (
        <RenderForm
          formName={formName}
          bids={bids}
          handleSubmit={handleSubmit}
          proxyBid={proxyBid}
          removeBid={removeBid}
          initForm={initForm}
          removeProxyBid={removeProxyBid}
          submitChangeWithType={submitChangeWithType}
          type={type}
        />
      ) : (
        <RenderList bids={bids} />
      )}
      <Actions>
        {!isFormToggled && userRole === 'admin' && ['Admin', 'Manager'].includes(userJob) && (
          <Button onClick={openRemoveModal} color="primary" variant="outlined">
            {deleteButton}
          </Button>
        )}
        <Button onClick={userRole === roles.ADMIN ? toggleWithInit : null} color="primary" variant="outlined">
          {cancelText}
        </Button>
      </Actions>
      <ConfirmModal
        title={confirmDeleteAll}
        handleSubmit={handleRemoveAll}
        submitText={'Delete'}
        modalId={`remove-${modalId}`}
      />
    </TooltipWrapper>
  );
};

const mapDispatchToProps = dispatch => ({
  removeBid: payload => dispatch(deleteBid(payload).request),
  removeProxyBid: payload => dispatch(deleteProxyBid(payload).request),
  updateBid: payload => dispatch(editBid(payload).request),
  updateProxyBid: payload => dispatch(editProxyBid(payload).request)
});

const createComponent = component =>
  compose(
    connect(null, mapDispatchToProps),
    reduxForm({
      onSubmit: (values, dispatch, props) => {
        const { proxyBid, bids } = props;
        const [lastBid] = bids;
        const actions =
          props.type === 'bids'
            ? {
                updateBid: payload => dispatch(editBid(payload).request),
                updateProxyBid: payload => dispatch(editProxyBid(payload).request)
              }
            : {
                updateProxyBid: payload => dispatch(adminOffersApi.endpoints.updateProxyOfferBid.initiate(payload)),
                updateBid: payload => dispatch(adminOffersApi.endpoints.updateOfferBid.initiate(payload))
              };
        if (values.type === BID_TYPES.NORMAL) {
          actions.updateBid({ amount: values.bid_amount, id: lastBid.id });
        } else if (values.type === BID_TYPES.PROXY) {
          actions.updateProxyBid({ amount: values.proxy_bid_amount, id: proxyBid.id });
        }
      }
    })
  )(component);

export const ViewAllBidsTooltip = createComponent(ViewAllBidsTooltipFC);
export default createComponent(ViewAllBidsModalFC);
