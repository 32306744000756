import { Creatable as CreatableSelect } from 'react-select';

import { useCreateFakeUserMutation, useGetFakeUsersQuery } from '../../services/api/admin-users';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useField, useForm, useFormState } from 'react-final-form';

type Props = {
  companyName: 'acv' | 'other' | 'open_lane';
};

type Option = { label: string; value: string };

const StyledSelect = styled(CreatableSelect)`
  & > div {
  }
  & .fake-user-select__control {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
  }

  & .fake-user-select__control:hover {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
  }

  & .fake-user-select__value-container {
    padding: 0;
  }

  & .fake-user-select__placeholder {
    margin-left: 0;
    color: #757575;
    opacity: 0.7;
  }

  & .fake-user-select__control--is-focused {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
    box-shadow: none;
  }

  & .fake-user-select__dropdown-indicator,
  .fake-user-select__clear-indicator {
    padding: 6px;
  }

  & .fake-user-select__control {
    min-height: unset;
  }

  & .fake-user-select__menu-list {
    padding: 4px 4px 4px 0;
  }

  & .fake-user-select__option--is-focused {
    background-color: rgba(0, 0, 0, 0.14) !important;
  }

  & .fake-user-select__option--is-selected {
    font-weight: 500;
    background-color: #fff !important;
    color: inherit !important;
  }
`;

export const FakeUserDropdown = ({ companyName }: Props) => {
  const { data, isFetching } = useGetFakeUsersQuery();
  const [createUser, { isLoading }] = useCreateFakeUserMutation();

  const [options, setOptions] = useState<Option[]>([]);
  // const [value, setValue] = useState<Option | null>(null);

  const { input } = useField(`${companyName}_fake_user_id`);

  const { values } = useFormState();

  const isDisabled = () => {
    if (!values) return false;
    if (!values[`${companyName}_bid`]) return true;
    return Object.keys(values).some(key => {
      if (key === `${companyName}_fake_user_id` || !key.endsWith('fake_user_id')) {
        return false;
      }

      return !!values[key];
    });
  };

  const value = useMemo(() => {
    return options.find(o => Number(o.value) === input.value);
  }, [options, input]);

  useEffect(() => {
    setOptions(data?.[companyName]?.map(user => ({ value: String(user.id), label: user.dealership_name })) || []);
  }, [data, companyName]);

  const handleCreate = async (dealerName: string) => {
    const response = await createUser({ dealership_name: dealerName, company_name: companyName });
    if ('data' in response) {
      const option = { value: String(response.data.data.id), label: response.data.data.dealership_name };
      // setValue(option);
      input.onChange(response.data.data.id);
      setOptions(curr => [option, ...curr]);
    }
  };

  const handleChange = (option: Option | null) => {
    // setValue(option);
    input.onChange(option ? Number(option.value) : null);
  };

  if (!data) return null;

  return (
    <StyledSelect
      classNamePrefix="fake-user-select"
      isClearable
      menuPosition="fixed"
      isDisabled={isLoading || isFetching || isDisabled()}
      options={options}
      onChange={handleChange}
      onCreateOption={handleCreate}
      value={value}
    />
  );
};
