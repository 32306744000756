/* eslint-disable no-underscore-dangle */
import React from 'react';
import NumberFormat from 'react-number-format';
import FormTextField from './FormTextField';
import styled from 'styled-components';

const StyledWarning = styled.span`
  color: #f44336;
  margin-top: 8px;
  font-size: 12px;
`;

function FormFormattedField(props) {
  const {
    inputRef,
    input: { name, onChange, onBlur, onFocus, value },
    maskProps,
    placeholderOnFocus,
    maxChars = 9,
    changeAction = null,
    ...other
  } = props;

  return (
    <>
      <NumberFormat
        getInputRef={inputRef}
        onValueChange={(_, e) => {
          onChange(e);
          if (changeAction) {
            changeAction();
          }
        }}
        customInput={FormTextField}
        onBlur={e => {
          // Returning old label on blur
          if (other.label) {
            e.target.placeholder = other.label;
          }
          onBlur(e);
        }}
        onFocus={e => {
          // Showing format on focus if value not specified
          if (!e.target.value && ((maskProps && maskProps.format) || placeholderOnFocus)) {
            e.target.placeholder = placeholderOnFocus || maskProps.format;
          }
          onFocus(e);
        }}
        name={name}
        value={value}
        isAllowed={value => {
          if (maskProps) return true;
          return value.value.length <= maxChars;
        }}
        {...other}
        {...maskProps}
      />
      {props?.meta?.touched && props?.meta?.data?.warning && <StyledWarning>{props.meta.data.warning}</StyledWarning>}
    </>
  );
}

export default FormFormattedField;
