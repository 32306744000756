import React from 'react';
import { change, formValueSelector, SubmissionError, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormLabel, Divider, MenuItem, Typography, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import { FormTextField, FormCheckbox, FormError } from '../../../../components/Form';
import { AdditionalFlooringForms, FormFileField, StyledGrid } from '../components';
import { updateProfile, updateProfileDoc } from '../../../../actions/user';
import { maxLength, required } from '../../../../utils/validators';
import { FLOORING_FORM_COMPANIES, REGISTRATION_MAX_STRING_LENGTH } from '../../../../constants';
import scrollToError from '../../../../utils/scrollToError';

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

const StyledCheckBoxWrapper = styled(StyledGrid)`
  margin-left: 12px;
  span:nth-child(1) {
    margin-right: 5px;
    padding: 0;
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: 15px;
`;

const StyledDisclosure = styled(Typography)`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const renderBasicFields = (fields, props = {}) =>
  fields.map(({ name = '', label = '', sm = 12, validation = [required, maxStringLength], ...rest }, index) => (
    <StyledGrid item sm={sm} key={index}>
      <Field name={name} component={FormTextField} label={label} fullWidth validate={validation} {...props} {...rest} />
    </StyledGrid>
  ));

const achFormFields = [
  {
    name: 'ach_form.full_account_name',
    label: 'Full account name*'
  },
  {
    name: 'ach_form.bank_account',
    label: 'Bank account #*'
  },
  {
    name: 'ach_form.bank_routing',
    label: 'Bank routing #*',
    sm: 6
  },
  {
    name: 'ach_form.bank_name',
    label: 'Bank name*',
    sm: 6
  },
  {
    name: 'ach_form.bank_address',
    label: 'Bank address',
    validate: undefined
  },
  {
    name: 'ach_form.bank_contact',
    label: 'Bank contact',
    validate: undefined,
    sm: 6
  },
  {
    name: 'ach_form.bank_phone',
    label: 'Bank phone',
    validate: undefined,
    sm: 6
  }
];

const flooringFormFields = [
  {
    name: 'flooring_form.name_act',
    label: 'Name on Acct'
  },
  {
    name: 'flooring_form.act',
    label: 'Acct #'
  },
  {
    name: 'flooring_form.auction_access',
    label: 'Auction Access #',
    validation: maxStringLength
  }
];

const checkBoxes = [
  {
    name: 'purchase_method.cash',
    label: 'Cash'
  },
  {
    name: 'purchase_method.company_check',
    label: 'Company Check'
  },
  {
    name: 'purchase_method.cashiers_check',
    label: "Cashier's check"
  },
  {
    name: 'purchase_method.ach',
    label: 'ACH'
  },
  {
    name: 'purchase_method.flooring',
    label: 'Flooring'
  }
];

const StyledFormLabel = styled(FormLabel)`
  && {
    margin: 26px 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.25px;
    color: #222222;
  }
`;

const validate = (values, { dispatch }) => {
  if (values.purchase_method && values.purchase_method.flooring && !values.purchase_method.ach) {
    dispatch(change('purchaseMethodsForm', 'purchase_method.ach', true));
  }

  return {};
};

const renderCheckboxes = (array, props) =>
  array.map(({ label, name }, index) => (
    <StyledCheckBoxWrapper item key={index}>
      <FormControlLabel control={<Field name={name} component={FormCheckbox} {...props.InputProps} />} label={label} />
    </StyledCheckBoxWrapper>
  ));

const PurchaseMethodsForm = ({ handleSubmit, purchase_method: paymentMethod, error, InputProps }) => (
  <form onSubmit={handleSubmit}>
    <StyledGrid container direction="column" spacing={24}>
      {renderCheckboxes(checkBoxes, { InputProps })}
    </StyledGrid>
    <StyledGrid container spacing={24}>
      {paymentMethod && paymentMethod.ach && (
        <>
          <StyledGrid item xs={12}>
            <StyledFormLabel component="legend">{'Ach Voided Check'}</StyledFormLabel>
            <StyledDisclosure>
              By uploading a voided check and entering my flooring information below, I (we) have authority to and
              authorize AutoAxess to electronically debit my (our) account (and if necessary, electronically credit my
              (our) account to correct erroneous debits) as follows:
            </StyledDisclosure>
            <Field name="ach_voided_check_image" showControls={!InputProps.disabled} component={FormFileField} label="" id="ach_voided_check" type="file" />
            <StyledDivider />
            <StyledFormLabel component="legend">
              {paymentMethod.flooring ? 'ACH Form (is required when Flooring is chosen)' : 'ACH Form'}
            </StyledFormLabel>
            <StyledDisclosure>
              By filling out my (our) banking and flooring information below I (we) have authority to and authorize
              AutoAxess to Electronically debit my (our) account (and if necessary, electronically credit my (our)
              account to correct erroneous debits) as follows:
            </StyledDisclosure>
            <Field
              name="ach_form.account_type"
              component={FormTextField}
              label="Select Account Type*"
              fullWidth
              validate={required}
              select
              InputProps={InputProps}
            >
              <MenuItem value="checking">Checking</MenuItem>
              <MenuItem value="savings">Savings</MenuItem>
            </Field>
          </StyledGrid>
          {renderBasicFields(achFormFields, { InputProps })}
        </>
      )}
      {paymentMethod && paymentMethod.flooring && (
        <>
          <StyledGrid item xs={12}>
            <StyledDivider />
            <StyledFormLabel component="legend">Flooring Form</StyledFormLabel>
            <Field
              name="flooring_form.flooring_company"
              component={FormTextField}
              label="Select Flooring Company"
              fullWidth
              validate={required}
              select
              InputProps={InputProps}
            >
              {FLOORING_FORM_COMPANIES.map(company => (
                <MenuItem value={company.value}>{company.name}</MenuItem>
              ))}
            </Field>
          </StyledGrid>
          {renderBasicFields(flooringFormFields, { InputProps })}
          <StyledGrid item xs={12}>
            <AdditionalFlooringForms InputProps={InputProps} />
          </StyledGrid>
        </>
      )}
      {paymentMethod && (paymentMethod.ach || paymentMethod.flooring) && (
        <StyledGrid item xs={12}>
          <StyledDisclosure>
            I (we) understand that this authorization will remain in full force and effect until I (we) notify AutoAxess
            in writing or by phone that I (we) wish to revoke this authorization. I (we) understand that AutoAxess
            requires at least 30 days notice to cancel this authorization.
          </StyledDisclosure>
        </StyledGrid>
      )}
      {error && <FormError msg={error} />}
    </StyledGrid>
  </form>
);

const getSelectedMultipleCheckboxes = (checkboxes = {}) =>
  Object.keys(checkboxes).reduce((acc, key) => {
    if (checkboxes[key]) {
      return [...acc, key];
    }
    return acc;
  }, []);

const onSubmit = async (values, dispatch) => {
  const isPurchaseOptionChosen = Object.values(values.purchase_method || {}).filter(i => i).length;

  if (!isPurchaseOptionChosen) {
    // eslint-disable-next-line no-underscore-dangle
    throw new SubmissionError({
      _error: 'At least one purchase method should be chosen'
    });
  }

  const changedFile = values.ach_voided_check_image;
  if (changedFile instanceof File) {
    await new Promise((resolve, reject) =>
      dispatch(
        updateProfileDoc({
          payload: {
            name: 'ach_voided_check_image',
            document: changedFile
          },
          resolve,
          reject
        }).request
      )
    );
  }

  return new Promise((resolve, reject) =>
    dispatch(
      updateProfile({
        payload: {
          ...values,
          purchase_method: getSelectedMultipleCheckboxes(values.purchase_method)
        },
        resolve,
        reject
      }).request
    )
  );
};

const selector = formValueSelector('purchaseMethodsForm');

export default compose(
  reduxForm({
    form: 'purchaseMethodsForm',
    onSubmit,
    enableReinitialize: true,
    validate,
    onSubmitFail: scrollToError
  }),
  connect(state => ({
    purchase_method: selector(state, 'purchase_method')
  }))
)(PurchaseMethodsForm);
