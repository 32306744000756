import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';

export type BuyerPurchaseReportFilters = {
    status?: 'win' | 'lost' | 'all';
    auctionIds?: number[];
    startDate?: string;
    endDate?: string;
    referrerIds?: number[];
    buyerIds?: number[];
}

export type BuyerPurchaseReportState = {
  page: number;
  filters: BuyerPurchaseReportFilters;
  sortBy: string | null;
  sortOrder: 'asc' | 'desc' | null;
}

type BuyerPurchaseReportRootState = { buyerPurchaseReport: BuyerPurchaseReportState }

const initialState: BuyerPurchaseReportState = {
  page: 1,
  filters: {},
  sortBy: null,
  sortOrder: null,
};

const buyerPurchaseReportSlice = createSlice({
  name: 'buyerPurchaseReport',
  initialState,
  reducers: {
      setFilterByKey(state, action: PayloadAction<{key: keyof BuyerPurchaseReportFilters, value: any}>) {
        const { value, key } = action.payload;
        if (value == null) { 
          delete state.filters[key]
        } else if (key === 'auctionIds' || key === 'referrerIds' || key === 'buyerIds') {
            state.filters[key] = value
        } else {
          state.filters[key] = value
        }

        state.page = 1
      },
      setPage(state, action: PayloadAction<number>) {
        state.page = action.payload
      },
      resetFilters(state) {
        state.filters = {}
        state.sortBy = null
        state.sortOrder = null
        state.page = 1
      },
      setSorting: (state, action: PayloadAction<{ sortBy: string | null; sortOrder: 'asc' | 'desc' | null }>) => {
        state.sortBy = action.payload.sortBy;
        state.sortOrder = action.payload.sortOrder;
        state.page = 1; // Reset page when sorting changes
      },
    }
});

export const useBuyerReportFilters = () => {
  const filters = useSelector((state: { buyerPurchaseReport: BuyerPurchaseReportState }) => state.buyerPurchaseReport.filters)
  return filters
}

export const useBuyerReportPage = () => {
  const page = useSelector((state: { buyerPurchaseReport: BuyerPurchaseReportState }) => state.buyerPurchaseReport.page)
  return page
}

export const useBuyerReportSorting = () => {
  const sortBy = useSelector((state: BuyerPurchaseReportRootState) => state.buyerPurchaseReport.sortBy);
  const sortOrder = useSelector((state: BuyerPurchaseReportRootState) => state.buyerPurchaseReport.sortOrder);
  return { sortBy, sortOrder };
};

export const { setPage, setFilterByKey, resetFilters, setSorting } = buyerPurchaseReportSlice.actions
export default buyerPurchaseReportSlice;
