import React from 'react';
import { submit, reset } from 'redux-form';
import { connect } from 'react-redux';
import { SectionBlock } from '../components';
import { ManageDocumentsForm } from '../forms';

const ManageDocumentsSection = ({ initialValues, submitForm }) => {
  const formInitialValues = (initialValues.buyer.docs || []).reduce(
    (acc, item) => ({
      ...acc,
      [item.name]: item
    }),
    {}
  );

  return (
    <SectionBlock title="Dealer License" submit={submitForm} canBeEdited={false}>
      <ManageDocumentsForm initialValues={formInitialValues} />
    </SectionBlock>
  );
};

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('manageDocumentsForm')),
  resetForm: () => dispatch(reset('manageDocumentsForm'))
});

export default connect(null, mapDispatchToProps)(ManageDocumentsSection);
