import styled from 'styled-components';
import { Field, Form, useForm } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';
import ModalWrapper from '../ModalWrapper';
import { updateAdminNotes } from '../../actions/vehicles';
import FormTextField from '../Form/FormTextField';
import { maxLength, minValue, validateMinBid, validateReservePrice } from '../../utils/validators';
import { normalizeFormattedField } from '../../utils';
import { FormCheckbox, FormFormattedField } from '../Form';
import { getMinBitAmount } from 'utils/vehicle';
import { FakeUserDropdown } from 'components/FakeUserDropdown';
import { useDispatch } from 'react-redux';
import {
  useGetMinBuyerOfferAmountQuery,
  useLazyGetMinBuyerOfferAmountQuery
} from '../../services/api/admin-auction-vehicles';
import moment from 'moment';

const maxNotesLength = maxLength(50);

const CheckboxWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: -2px;
`;

const validateFakeBid = (vehicle, prefix, getMinAmount) => {
  const isVehicleTimerEnded = vehicle.date_end && moment.utc(vehicle.date_end).isBefore(moment.utc());
  const typeLabel = isVehicleTimerEnded ? 'Offer' : 'Bid';

  return async (value, values) => {
    if (!value || !values[`${prefix}_fake_user_id`]) return;
    const { data: minBidAmountResponse } = await getMinAmount({
      vehicleId: vehicle.id,
      buyerId: values[`${prefix}_fake_user_id`]
    });

    if (!minBidAmountResponse) return;
    if (!minBidAmountResponse.offerAllowed) {
      return minBidAmountResponse?.disallowedReason || `Invalid bid`;
    }

    const selected = values[`${prefix}_checkbox`];
    const minBid = minBidAmountResponse.amount - 100;
    if (value <= minBid) {
      return `${typeLabel} should be higher then ${minBid}`;
    }

    if (!selected && value - minBid <= 100) {
      return `Min ${typeLabel} is ${minBid + 100}`;
    }
  };
};

const StyledFieldRow = styled.div`
  display: flex;
  margin: 15px 0;
  gap: 8px;
  & > div {
    flex: 1 1 100%;
  }
`;

const StyledBidWrapper = styled(Grid)`
  position: relative;
  & * p {
    position: absolute;
    bottom: -14px;
    white-space: nowrap;
  }
`;

const NotesModal = ({ modalId, vehicle, initialValues }) => {
  const isVehicleTimerEnded = vehicle.date_end && moment.utc(vehicle.date_end).isBefore(moment.utc());

  const bidPlaceholder = isVehicleTimerEnded ? 'Offer' : 'Bid';

  const dispatch = useDispatch();
  const handleSubmit = values => {
    const fakeBid =
      checkFakeBid(values, 'open_lane') || checkFakeBid(values, 'acv') || checkFakeBid(values, 'other') || null;

    dispatch(
      updateAdminNotes({
        id: vehicle.id,
        admin_notes: values.adminNotes,
        reserve_price: values.reserve_price || null,
        starting_bid: values.starting_bid || 100,
        acv_offers: createCompanyInput(values, 'acv'),
        other_offers: createCompanyInput(values, 'other'),
        open_lane_offers: createCompanyInput(values, 'open_lane'),
        fake_bid: isVehicleTimerEnded ? null : fakeBid,
        fake_offer: isVehicleTimerEnded ? fakeBid : null
      }).request
    );
  };

  return (
    <Form onSubmit={handleSubmit} keepDirtyOnReinitialize initialValues={initialValues}>
      {props => <RenderForm vehicle={vehicle} vehicleId={vehicle.id} modalId={modalId} {...props} />}
    </Form>
  );
};

const RenderForm = props => {
  const fakeBuyerId =
    props.values.open_lane_fake_user_id || props.values.acv_fake_user_id || props.values.other_fake_user_id || null;

  const [getMinAmount] = useLazyGetMinBuyerOfferAmountQuery();
  // const { data: minOfferAmount } = useGetMinBuyerOfferAmountQuery(
  //   {
  //     vehicleId: props.vehicleId || 0,
  //     buyerId: fakeBuyerId
  //   },
  //   {
  //     skip: !fakeBuyerId
  //   }
  // );

  const vehicle = props.vehicle;

  const validateOpenLane = validateFakeBid(vehicle, 'open_lane', getMinAmount);
  const validateAcv = validateFakeBid(vehicle, 'acv', getMinAmount);
  const validateOther = validateFakeBid(vehicle, 'other', getMinAmount);

  const isVehicleTimerEnded = vehicle.date_end && moment.utc(vehicle.date_end).isBefore(moment.utc());
  const bidPlaceholder = isVehicleTimerEnded ? 'Offer' : 'Bid';

  return (
    <ModalWrapper
      title="Notes & Reserve"
      modalId={props.modalId}
      submitText="Save"
      className="notes-dialog"
      cancelAdditionalAction={() => {
        props.reset();
      }}
      handleSubmit={props.handleSubmit}
      onClose={() => {
        props.reset();
      }}
    >
      <StyledFieldRow>
        <Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Admin notes</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="adminNotes"
              component={FormTextField}
              margin="dense"
              placeholder="Admin notes"
              type="text"
              fullWidth
              validate={maxNotesLength}
            />
          </Grid>
        </Grid>
      </StyledFieldRow>
      <StyledFieldRow>
        <Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Reserve Price</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="reserve_price"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder="Reserve price"
              type="text"
              fullWidth
              parse={normalizeFormattedField}
              validate={validateReservePrice}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Starting Bid</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="starting_bid"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder="Starting bid"
              type="text"
              fullWidth
              parse={normalizeFormattedField}
              validate={validateMinBid}
            />
          </Grid>
        </Grid>
      </StyledFieldRow>
      <StyledFieldRow>
        <Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Open Lane</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="open_lane_opening_bid"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder="Opening Bid"
              type="text"
              fullWidth
              parse={normalizeFormattedField}
            />
          </Grid>
          <StyledBidWrapper item xs={12} style={{ position: 'relative' }}>
            <Field
              name="open_lane_bid"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder={bidPlaceholder}
              type="text"
              fullWidth
              validate={validateOpenLane}
              parse={normalizeFormattedField}
              changeAction={() => props.form.change('open_lane_checkbox', false)}
            />
            <CheckboxWrapper>
              <Field name="open_lane_checkbox" component={FormCheckbox} fullWidth />
            </CheckboxWrapper>
          </StyledBidWrapper>
          <Grid item xs={12}>
            <Field
              name="open_lane_sold"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder="Sold"
              type="text"
              fullWidth
              parse={normalizeFormattedField}
            />
          </Grid>
          <Grid item xs={12}>
            <FakeUserDropdown companyName="open_lane" />
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12}>
            <Typography variant="body1">ACV</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="acv_opening_bid"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder="Opening Bid"
              type="text"
              fullWidth
              parse={normalizeFormattedField}
            />
          </Grid>
          <StyledBidWrapper item xs={12}>
            <Field
              name="acv_bid"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder={bidPlaceholder}
              type="text"
              fullWidth
              validate={validateAcv}
              parse={normalizeFormattedField}
              changeAction={() => props.form.change('acv_checkbox', false)}
            />
            <CheckboxWrapper>
              <Field name="acv_checkbox" component={FormCheckbox} fullWidth />
            </CheckboxWrapper>
          </StyledBidWrapper>
          <Grid item xs={12}>
            <Field
              name="acv_sold"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder="Sold"
              type="text"
              fullWidth
              parse={normalizeFormattedField}
            />
          </Grid>
          <Grid item xs={12}>
            <FakeUserDropdown companyName="acv" />
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Other</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="other_opening_bid"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder="Opening bid"
              type="text"
              fullWidth
              parse={normalizeFormattedField}
            />
          </Grid>
          <StyledBidWrapper item xs={12} style={{ position: 'relative' }}>
            <Field
              name="other_bid"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder={bidPlaceholder}
              type="text"
              fullWidth
              validate={validateOther}
              parse={normalizeFormattedField}
              changeAction={() => props.form.change('other_checkbox', false)}
            />
            <CheckboxWrapper>
              <Field name="other_checkbox" component={FormCheckbox} fullWidth />
            </CheckboxWrapper>
          </StyledBidWrapper>
          <Grid item xs={12}>
            <Field
              name="other_sold"
              component={FormFormattedField}
              thousandSeparator
              allowNegative={false}
              fixedDecimalScale
              prefix="$"
              margin="dense"
              placeholder="Sold"
              type="text"
              fullWidth
              parse={normalizeFormattedField}
            />
          </Grid>
          <Grid item xs={12}>
            <FakeUserDropdown companyName="other" />
          </Grid>
        </Grid>
      </StyledFieldRow>
    </ModalWrapper>
  );
};

const checkFakeBid = (values, prefix) => {
  const dealerKey = `${prefix}_fake_user_id`;
  const bidKey = `${prefix}_bid`;

  if (values[dealerKey] && Number(values[bidKey])) {
    return {
      user_id: values[dealerKey],
      amount: Number(values[bidKey]),
      checked: values[`${prefix}_checkbox`]
    };
  }
};

const createCompanyInput = (values, prefix) => {
  return {
    opening_bid: Number(values[`${prefix}_opening_bid`]) || null,
    bid: Number(values[`${prefix}_bid`]) || null,
    sold: Number(values[`${prefix}_sold`]) || null,
    fake_user_id: Number(values[`${prefix}_fake_user_id`]) || null,
    checked: values[`${prefix}_checkbox`] || false
  };
};

export default NotesModal;
